
<template>
  <div>
    <span class="text-center table-edit-icon" @click="popupActiveDCR = true">
      <i class="text-center material-icons">delete</i>
    </span>
    <div class="demo-alignment">
      <!-- :id="'dcrpop' + params.data.dcr_id" -->
      <vs-popup
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Delete DCR"
        :active.sync="popupActiveDCR"
      >
        <div class="text-center">
          <h5>Do you want to delete DCR?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import dcrService from "@/services/dcrService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActiveDCR: false,
    };
  },
  beforeMount() {},
  beforeDestroy() {
    // let _id = document.getElementById("dcrpop" + this.params.data.dcr_id);
    // _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function (e, value) {
      this.$vs.loading();
      let OLDdata = [];
      OLDdata.push(this.params.data);

      let arr = OLDdata.map((x) => {
        if (x.dcr_isActive == 1 || x.dcr_isActive == "1") {
          x.dcr_isActive = 3;
        }
        x["dcr_particulars"] = x["particulars"]; // Assign new key
        delete x["particulars"]; // Delete old key
        return x;
      });

      let payload = {
        ...arr[0],
      };
      dcrService
        .deleteDCR(payload)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (!data.error || data.Status == true) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            setTimeout(() => {
              eventBus.$emit("refreshEditDCRTable", data);
            }, 1);
            this.popupActiveDCR = false;
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Somthing went wrong!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    privStatus: function () {
      // this.$vs.loading.close();
      this.popupActiveDCR = false;
    },
  },
});
</script>
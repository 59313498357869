<template>
  <div class="dprDateInput">
    <input
      v-if="params.colDef.field == 'start_date' && params.rowIndex != 0"
      type="date"
      :min="fixedminDate"
      :max="fixedmaxEndDate"
      class="inputDate"
      @change="changeStartDate(params, params.data.dpr_id)"
      v-model="start_date"
      :disabled="userLogin.includes('Admin') ? false : true"
    />
    <input
      v-if="params.colDef.field == 'end_date' && params.rowIndex != 0"
      type="date"
      class="inputDate"
      :disabled="
        params.data.start_date != '' && userLogin.includes('Admin')
          ? false
          : true
      "
      :min="fixedminEndDate"
      :max="fixedmaxDate"
      @change="changeStartDate(params, params.data.dpr_id)"
      v-model="end_date"
    />
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import DPRService from "@/services/dprService";

export default Vue.extend({
  components: {
    Datepicker
  },
  data() {
    return {
      popupActive: false,
      fixedminDate: "",
      fixedmaxDate: "",
      fixedminEndDate: "",
      fixedmaxEndDate: "",
      particularItems: [],
      userLogin: window.localStorage.getItem("UserRole"),

      form: {
        dateFrom: "",
        dateTo: ""
      },
      startDisabledDates: {
        from: new Date()
      },
      start_date: "",
      end_date: ""
    };
  },
  beforeMount() {
    this.form = this.params.data;

    if (
      this.params.colDef.field == "start_date" &&
      this.form.start_date != "" &&
      this.form.start_date != null
    ) {
      //  console.log(this.form.start_date);
      this.start_date = moment(this.form.start_date).format("YYYY-MM-DD");
      // console.log(this.start_date, "fsdfdsf");
    } else if (
      this.params.colDef.field == "end_date" &&
      this.form.end_date != "" &&
      this.form.end_date != null
    ) {
      this.end_date = moment(this.form.end_date).format("YYYY-MM-DD");
      // console.log(this.end_date, "fsdfsdf");
    }
    this.fixedminDate = moment(this.form.start_date1).format("YYYY-MM-DD");
    this.fixedmaxEndDate = moment(this.form.end_date1).format("YYYY-MM-DD");
    this.fixedminEndDate = moment(this.form.start_date).format("YYYY-MM-DD");
    //  this.fixedmaxEndDate = moment(this.form.end_date).format("YYYY-MM-DD");
  },
  // beforeDestroy() {
  //   let _id = document.getElementById(
  //     "popmodalS" + this.params.data.department_id
  //   );
  //   console.log("_id", _id);
  //   _id.setAttribute("style", "display: none;");
  // },
  methods: {
    changeStartDateNew() {
      let filters = {
        dpr_id: this.params.data.dpr_id,
        project_id: this.params.data.project_id
      };
      DPRService.getParticularList(filters)
        .then(response => {
          const { data } = response;
          // console.log(data.data, "draftData");
          if (data.data.length > 0) {
            this.particularItems = data.data;
          } else {
            this.particularItems = [
              {
                add: "1",
                particular_name: "",
                unit_rate: 0,
                unit_no: 0,
                time_shift: 0,
                other_cost: 0,
                amount: 0,
                project_type: 1,
                show_reason: 0
              }
            ];
          }
          this.$vs.loading.close();
          this.gridApi.stopEditing();
        })
        .catch(error => {
          console.log(error);
        });
    },
    getDates(startDate, stopDate) {
      var dateArray = [];
      var currentDate = moment(startDate);
      var stopDate = moment(stopDate);
      while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format("DD-MM-YYYY"));
        currentDate = moment(currentDate).add(1, "days");
      }
      return dateArray;
    },
    changeStartDate(params, id) {
      console.log(params.data, this.form, id);
      let fromdate = "";
      let toDate = "";
      let checkStart = "";
      let checkEnd = "";
      let typeFrom = params.colDef.field;
      if (params.colDef.field == "start_date") {
        checkStart = this.start_date;
        checkEnd = this.form.end_date;
        fromdate = this.start_date;

        toDate =
          this.form.end_date != ""
            ? moment(this.form.end_date).format("YYYY-MM-DD")
            : moment(this.form.end_date1).format("YYYY-MM-DD");
      } else if (params.colDef.field == "end_date") {
        fromdate =
          this.form.start_date != ""
            ? moment(this.form.start_date).format("YYYY-MM-DD")
            : moment(this.form.start_date1).format("YYYY-MM-DD");
        checkStart = this.end_date;
        checkEnd = this.form.start_date;

        toDate = this.end_date;
      }
      // console.log(fromdate, toDate);
      let array = this.getDates(fromdate, toDate);
      let payload = {
        dateArray: array,
        index: params.rowIndex,
        fromdate: fromdate,
        toDate: toDate,
        typeFrom: typeFrom
      };

      console.log(checkStart, checkEnd, "fdsfdsfdsfdsfdsfdsfdsf");
      if (
        checkStart != "" &&
        checkEnd != "" &&
        checkStart != "" &&
        checkEnd != null
      ) {
        let payload2 = {
          dpr_id: this.params.data.dpr_id,
          type: 1,
          project_id: this.params.data.project_id,
          budget_id: this.params.data.budget_id,
          budget_start_date: fromdate,
          budget_end_date: toDate,
          particularItems: [],
          dpr_value: 0
        };

        DPRService.addDpr(payload2)
          .then(response => {
            const { data } = response;
            // this.$vs.notify({
            //   title: "",
            //   text: "Data Updated Successfully",
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "success"
            // });
            // this.reason = "";
            if (response.data.hasOwnProperty("dpr_id")) {
              payload["dpr_id"] = data.dpr_id;
            } else {
              payload["dpr_id"] = id;
            }

            eventBus.$emit("refreshTableStartDataUpdate", payload);
          })
          .catch(error => {
            this.$vs.notify({
              title: "Data Not Found",
              text: error.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          });
      } else {
        this.$vs.notify({
          time: 10000,
          title: "",
          text: `Kindly enter the END DATE as well to save the date range for ${params.data.budget_code_num} ${params.data.category_name} ${params.data.sub_category_name} `,
          iconPack: "feather",
          icon: "check_box",
          color: "warning"
        });
        payload["dpr_id"] = id;
        eventBus.$emit("refreshTableStartDataUpdate", payload);
      }
    }
    // onSelectDate: function($event) {
    //   this.endDisabledDates["to"] = new Date(
    //     moment(new Date($event))
    //       .add(1, "d")
    //       .format()
    //   );
    //   this.form.dateTo = "";
    //   this.toDateDisable = false;
    // },
  }
});

// import Vue from "vue";

// export default Vue.extend({
//   template: ` {{params.value}}
//          <label class="switch">
//           <input @click="onChangeCheckbox" type="checkbox">
//           <span class="slider round"></span>
//     </label> `,
//   data: function () {
//     return {};
//   },
//   beforeMount() {},
//   mounted() {},
//   methods: {

//   }
// });
</script>
<style>
.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}
.dprDateInput input[type="date"] {
  border: 1px solid #dee2e6 !important;
  width: 100%;
  height: auto;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>

<template>
  <div>
    <vs-button
      type="filled"
      class="mr-3"
      v-if="params.value == 'button'"
      style="padding: 0 36px; height: 41px;"
      @click="savebutton($event)"
      >Final Save
    </vs-button>
    <span v-else
      >{{ params.value == "-" ? "N/A" : params.value.toLocaleString('en-IN')
      }}<i v-if="params.value != '-'" class="material-icons float-right"
        >edit</i
      ></span
    >
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import CountryService from "@/services/countryService.js";
import DPRService from "@/services/dprService";

export default Vue.extend({
  data() {
    return {};
  },
  beforeMount() {
    // console.log(this.params);
    // this.form = this.params.data;
    // this.copy_country_active = this.form.country_active;
  },
  beforeDestroy() {},
  methods: {
    savebutton() {
      let project_id = this.params.data.project_id;
      let payload = {
        type_date: this.params.colDef.field
      };

      DPRService.updateAndSaveAll(payload, project_id)
        .then(response => {
          const { data } = response;
          eventBus.$emit("refreshTableDataUpdate", this.params.colDef.field);

          this.$vs.notify({
            title: "",
            text: "Data Updated Successfully",
            iconPack: "feather",
            icon: "check_box",
            color: "success"
          });
        })
        .catch(error => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        });
    }
  }
});
</script>

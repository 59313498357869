
<template>
  <div>
    <span class="text-center table-edit-icon" @click="changeRoute">
      <i class="text-center material-icons">edit</i>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import dcrService from "@/services/dcrService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActiveDCR: false,
    };
  },
  beforeMount() {},
  beforeDestroy() {},
  methods: {
    changeRoute() {
      let isTrue = true;
      eventBus.$emit("updatedDCRdata", isTrue, this.params.data);
    },
  },
});
</script>